import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { ThemeContext } from "../../context/ThemeContext";
import { RoleContext } from "../../App";
import {
  getAllUserRoles,
  getCurrentUserRole,
  getUserIDFromJWT,
  modifyAuthToken,
} from "../../lib/auth";
import UserIcon from "../../assets/icons/light/user_icon.svg";
import { switchUserRole } from "../../lib/models/Authorisation";
import Loader from "../Popups/Loader";
import PopUpsContainer from "../../containers/PopUpsContainer";
import { faro } from "@grafana/faro-web-sdk";
import { NEW_WEBSITE_URL } from "../../constants";

interface ProfileDropDownProps {
  profileURL: string;
  name: string;
  email: string;
  userRole: string;
  ROLES: { STUDENT: string; ADMIN: string; TRAINER: string };
  setProfileDropDown: (profileDropDown: boolean) => void;
}
function ProfileDropDown({
  profileURL,
  name,
  email,
  userRole,
  ROLES,
  setProfileDropDown,
}: ProfileDropDownProps): React.ReactElement {
  const navigate = useNavigate();
  const { appMode, setAppMode } = useContext(RoleContext);
  const ref = useRef() as React.RefObject<HTMLDivElement>;
  const handleClick = (e: MouseEvent): void => {
    if (
      (ref.current != null &&
        !ref.current.contains(e.target as HTMLDivElement)) ||
      (e.target as HTMLDivElement).className ===
        "dropdown-actions theme-mode" ||
      (e.target as HTMLDivElement).className === "role active"
    ) {
      setProfileDropDown(false);
    }
  };
  let isMounted = true;
  useEffect(() => {
    if (isMounted) document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
      isMounted = false;
    };
  }, []);
  const { theme, updateTheme } = useContext(ThemeContext);
  const allRoles = getAllUserRoles();
  const [isLoaderModalOpen, setIsLoaderModalOpen] = useState(false);
  return (
    <div className="dropdown user-profile">
      {isLoaderModalOpen && (
        <PopUpsContainer>
          <Loader />
        </PopUpsContainer>
      )}
      <div className="demo-wrapper" ref={ref}>
        <div className="profile-section">
          <div className="left-wrapper">
            <img
              src={profileURL}
              className="user-image"
              alt="Profile Pic"
              referrerPolicy="no-referrer"
              onError={(e) => {
                (e.target as HTMLInputElement).onerror = null;
                (e.target as HTMLInputElement).src = UserIcon;
              }}
            />
          </div>
          <div className="right-wrapper">
            <div className="user-name">{name}</div>
            <div className="user-email">{email}</div>
          </div>
        </div>
        <div className="bottom-section">
          {!(allRoles.length === 1 && allRoles.includes("STUDENT")) && (
            <div className="role-switching">
              <div className="role-switching-head">Switch Profile</div>
              <div className="roles-wrapper">
                {allRoles.map((role, index) => (
                  <div
                    key={index}
                    className={`role ${appMode === role ? "active" : ""}`}
                    onClick={() => {
                      if (appMode !== role) {
                        setIsLoaderModalOpen(true);
                        switchUserRole(role)
                          .then((jwt) => {
                            navigate("/dashboard");
                            modifyAuthToken(jwt);
                            setAppMode(getCurrentUserRole());
                            setIsLoaderModalOpen(false);
                          })
                          .catch((err) => {
                            setIsLoaderModalOpen(false);
                            if (faro?.api?.pushError) {
                              faro.api.pushError(err);
                            }
                          });
                      }
                    }}
                  >
                    {role.toUpperCase()}
                  </div>
                ))}
              </div>
            </div>
          )}
          {userRole !== ROLES.ADMIN && (
            <Link
              className="dropdown-actions my-profile"
              to={`/user/${getUserIDFromJWT() ?? ""}`}
            >
              My Profile
            </Link>
          )}
          {userRole === ROLES.STUDENT && (
            <>
              <Link
                className="dropdown-actions ongoing-batches"
                to="/batches/all"
              >
                My Batches
              </Link>
              <Link
                className="dropdown-actions ongoing-paths"
                to="/paths/ongoing"
              >
                Ongoing Paths
              </Link>
              <Link
                className="dropdown-actions ongoing-courses"
                to="/courses/ongoing"
              >
                Ongoing Courses
              </Link>
              <div className="dropdown-actions watchlist disabledbutton">
                Watchlist
              </div>
              <Link
                className="dropdown-actions wishlist"
                to="/courses/favourite"
              >
                Favourite Courses
              </Link>
            </>
          )}
          {(userRole === ROLES.ADMIN || userRole === ROLES.TRAINER) && (
            <>
              <Link className="dropdown-actions all-paths" to="/paths/all">
                All Paths
              </Link>
              <Link className="dropdown-actions all-courses" to="/courses/all">
                All Courses
              </Link>
              {userRole === ROLES.ADMIN && (
                <Link
                  className="dropdown-actions all-students"
                  to="/user/students/all"
                >
                  All Students
                </Link>
              )}
            </>
          )}
          <div className="dropdown-actions notifications disabledbutton">
            Notifications
          </div>
          <div
            className="dropdown-actions theme-mode"
            onClick={() => {
              updateTheme();
            }}
          >
            {theme === "light" ? "Dark Mode" : "Light Mode"}
          </div>
          <Link className="dropdown-actions ongoing-courses" to="/invitations">
            Invitations
          </Link>
          <div
            className="dropdown-actions navigate-newsite"
            onClick={() => {
              if (userRole === ROLES.TRAINER) {
                window.location.href = `${NEW_WEBSITE_URL}/v2/trainer`;
              } else if (userRole === ROLES.STUDENT) {
                window.location.href = `${NEW_WEBSITE_URL}/v2/student`;
              }
            }}
          >
            Go to New site
          </div>
          <div
            className="dropdown-actions logout"
            onClick={() => {
              navigate("/logout");
            }}
          >
            Logout
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileDropDown;
