declare global {
  interface Window {
    env: {
      REACT_APP_FARO_URL: string;
      REACT_APP_FARO_APP_NAME: string;
      REACT_APP_GOOGLE_LOGIN_CLIENT_ID: string;
      REACT_APP_BASE_URL: string;
      REACT_APP_MEASUREMENT_ID: string;
      REACT_NEW_WEBSITE: string;
    };
  }
}
export const GOOGLE_LOGIN_CLIENT_ID =
  window?.env?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID !== undefined &&
  window?.env?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID !== null
    ? window?.env?.REACT_APP_GOOGLE_LOGIN_CLIENT_ID
    : process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;

export const BASE_URL = (
  window?.env?.REACT_APP_BASE_URL !== undefined &&
  window?.env?.REACT_APP_BASE_URL !== null
    ? window?.env?.REACT_APP_BASE_URL
    : process.env.REACT_APP_BASE_URL
) as string;

export const NEW_WEBSITE_URL = (
  window?.env?.REACT_NEW_WEBSITE !== undefined &&
  window?.env?.REACT_NEW_WEBSITE !== null
    ? window?.env?.REACT_NEW_WEBSITE
    : process.env.REACT_NEW_WEBSITE
) as string;

// export const BASE_URL = "http://localhost:8080";

export const FARO_APP_NAME =
  window.env?.REACT_APP_FARO_APP_NAME ?? process.env.REACT_APP_FARO_APP_NAME;

export const FARO_APP_URL =
  window.env?.REACT_APP_FARO_URL ?? process.env.REACT_APP_FARO_URL;

export const MEASUREMENT_ID =
  window?.env?.REACT_APP_MEASUREMENT_ID !== undefined &&
  window?.env?.REACT_APP_MEASUREMENT_ID !== null
    ? window?.env?.REACT_APP_MEASUREMENT_ID
    : process.env.REACT_APP_MEASUREMENT_ID;

export const batchCardsBackgroundImages = [
  "linear-gradient(270deg, #00A2EE, #0078C8)",
  "linear-gradient(30deg, #FF5C83, #E392CA)",
  "linear-gradient(270deg, #8BACFE, #6C59FF)",
  "linear-gradient(270deg, #00E4B5, #00AF73)",
];

export const streamCardsBackgroundColors = [
  "linear-gradient(to bottom right, #7f7fd5, #86a8e7, #91eae4)",
  "linear-gradient(to bottom right, #26d0ce, #1a2980)",
  "linear-gradient(to bottom right, #12c2e9, #4a00e0)",
  "linear-gradient(to bottom right, #2679b0, #6dd5fa, #adffb0)",
];

export const resourcesMockdata = [
  {
    id: -1,
    resourceName: "Sample Resource Name",
    resourceLink: "https://www.dummylink.com/",
    isCompleted: true,
  },
  {
    id: -1,
    resourceName: "Sample Resource Name",
    resourceLink: "https://www.dummylink.com/",
    isCompleted: true,
  },
  {
    id: -1,
    resourceName: "Sample Resource Name",
    resourceLink: "https://www.dummylink.com/",
    isCompleted: true,
  },
];

export const chaptersMockdata = [
  {
    id: -1,
    name: "Dummy Chapter name",
    resources: [
      {
        id: -1,
        resourceName: "Sample Resource Name",
        resourceLink: "https://www.dummylink.com/",
        isCompleted: true,
      },
    ],
    quizzes: [],
    progress: 100,
  },
  {
    id: -1,
    name: "Dummy Chapter name",
    resources: [
      {
        id: -1,
        resourceName: "Sample Resource Name",
        resourceLink: "https://www.dummylink.com/",
        isCompleted: true,
      },
      {
        id: -1,
        resourceName: "Sample Resource Name",
        resourceLink: "https://www.dummylink.com/",
        isCompleted: true,
      },
    ],
    quizzes: [],
    progress: 100,
  },
  {
    id: -1,
    name: "Dummy Chapter name",
    resources: [
      {
        id: -1,
        resourceName: "Sample Resource Name",
        resourceLink: "https://www.dummylink.com/",
        isCompleted: true,
      },
    ],
    quizzes: [],
    progress: 100,
  },
];

export const superAdminEmail = "superadmin@skillup.com";
